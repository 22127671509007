import React from 'react'

import Layout from "../components/layout"
import SEO from "../components/seo"

const Covid = () => (

    <Layout pageClass="covid-page">
    <SEO title="Covid 19 Notice" />
        <main className="main">
            <div className="typography inner">
                <h1>COVID-19 Business Advisory Fund</h1>
                <p>
                  Experience on Tap Ltd. offers services that are registered with the COVID-19 Business Advisory Fund. Find out more by visiting <a href="https://covid19.nzte.govt.nz/page/regional-business-partner-network">covid19.nzte.govt.nz/page/regional-business-partner-network</a>.
                </p>

                <p>
                Small and medium sized businesses may qualify for funding to help pay for advisory services to support them through the Covid-19 outbreak.
                </p> 
                
                <p>
                    The Covid-19 Business Advisory Funding can provide support in areas such as HR, health and wellbeing, business continuity, cashflow and finance management, marketing and digital enablement strategy. 
                </p>

                <p>
                    Funding is only available through the Regional Business Partner Network (RBP) Growth Advisors.
                </p>

                <p>
                    For more information or to find your local Growth Advisor go to <a href="https://covid19.nzte.govt.nz/page/regional-business-partner-network">covid19.nzte.govt.nz/page/regional-business-partner-network</a>.
                </p>
               
            </div>
        </main>
    </Layout>

)


export default Covid